/* ===========================
   Mixins
   =========================== */

/*
	Transition
 */

@mixin transition( $timing: .8s ) {
	transition: all #{$timing} ease;
}


/*
	Breakpoints
 */

$phone: "(max-width: #{$phablet-width})";
$phablet: "(min-width: #{$phablet-width + 1}) and (max-width: #{$tablet-width})";
$tablet: "(min-width: #{$tablet-width + 1}) and (max-width: #{$laptop-width})";
$laptop: "(min-width: #{$laptop-width + 1}) and (max-width: #{$desktop-width})";
$desktop: "(min-width: #{$desktop-width})";

// Shortcuts
$large: #{$laptop}, #{$desktop};
$medium: #{$tablet};
$small: #{$phone}, #{$phablet};

// Alias for Nav
$nav-desktop: #{$laptop}, #{$desktop};
$nav-mobile: #{$phone}, #{$phablet}, #{$tablet};

/*@media #{$small} {  } */

/*
	Media Queries Mixins
 */
@mixin custom($size, $range: 'max') {
  @media only screen and (#{$range}-width: #{$size}) {
    @content;
  }
}
// @include custom(1280px) {  }
