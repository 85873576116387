/* ===========================
   Base
   =========================== */

/*
	Webfonts
 */




/*
	General
 */

.preload * {
	transition: none !important;
}

@viewport {
	width: device-width;
}

html, body {
	min-height: 100%;
	height: 100vh;
}

html {
	font-family: "Helvetica Neue", Helvetica, sans-serif; // Arial

	@media #{ $small, $tablet } {
		background-color: $lightgrey;
	}

}

body {
	font-variant-ligatures: no-common-ligatures;
	background: {
		color: $white;
		size: cover;
		repeat: no-repeat;
	}
	color: $black;

	/*
	&:not(.home) {
		@media #{ $small } {
			background-image: none !important;
		}
	}
	*/

}

h1, h2, h3, h4, h5, h6 {
	font-size: 1rem;
}

.heading-1, .heading-2, .heading-3, .heading-4 {
	margin-top: 2rem;
	margin-bottom: 1rem;
	&:first-child {
		margin-top: 0;
	}
	word-wrap: break-word;
}

.heading-1 {
	font-size: 1.6rem;
	color: $white;
	text-transform: uppercase;
}

.heading-2 {
	font-size: 1.1rem;
}

.heading-3 {
	font-size: 1rem;
}

a {
	text-decoration: none;
	color: $black;
	transition: color .3s ease;

	&:hover {
		color: rgba($black, .5);
	}
}

p {
	margin-bottom: 1rem;

	&:last-child {
	margin-bottom: 0;
	}
}

img {
	width: 100%;
	max-width: 100%;
}

strong {
	font-weight: 700;
	//font-weight: 800;
	//@extend .title--sub;
}

ul {
	list-style: inside;

	.l-content__inner & li {
		margin-bottom: .8rem;
	}
}