/* =======================
   Layouts
   ======================= */

%section-spacing {

    padding: 0 1.8rem;

}

/*
    Wrapper
 */

.l-main {

    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    max-width: 1460px;
    margin: 0 auto;

    @media #{$nav-mobile} {
        justify-content: flex-start;
    }

    @media #{$small} {
        flex-direction: column;
    }

}


/*
    Visual
 */

.l-visual {

    flex: 0 0 310px; // 0 1 360px
    background: {
        image: url(../images/punkte_repeat.png);
        size: 24px;
        repeat: repeat;
        position: center;
    }

    @media #{$small, $medium, $laptop} {
        display: none;
    }

}


/*
    Fullscreen
 */

.l-fullscreen {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: $sidebar_width;
    bottom: 0;
    z-index: 100;

    .m-image__scheme {
        background: {
            size: contain;
            color: black;
        }
        height: 100%;
    }

}


/*
    Archive
 */

.l-archive {

    @extend .l-fullscreen;
    display: flex;
    background-color: $grey;

    .l-content {
        width: 100%;
        max-width: none;
    }

    .heading-1 {
        color: $black;
    }

    .l-nav {
        display: none;

        @media #{$small, $tablet} {
            display: block;
        }
    }

    @media #{$small, $tablet} {
        position: static;
        order: 2;
        z-index: 10;
    }

}


/*
    Navigation
 */

.l-nav {

    flex: 1 0 250px; // 0 0 220px

    display: flex;
    flex-direction: column;
    justify-content: center;
    @extend %section-spacing;
    padding: 0 1rem;
    position: relative;

    .heading-1 {
        position: absolute;
        top: 1.2rem;
        left: 1rem;
    }

    @media #{$nav-mobile} {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        width: $offcanvas_width;
        justify-content: flex-start;
        background-color: $grey;
        padding: 0 1.2rem;
        margin-top: 4rem;
        z-index: 1000;
        transform: translateX(-100%);
        border-right: solid 2px $white;
        transition: transform .5s ease;

        .heading-1 {
            position: static;
            margin-top: 1rem;
            font-size: 1.3rem;
            line-height: 1.4;
            color: $red;
        }

        .nav {
            margin-top: .5rem;
        }

        // Open state
        &.is-open {
            transform: translateY(0);
        }
    }
}


/*
    Content
 */

.l-content {
    flex: 1 1 $content_width;
    max-width: 850px;
    min-width: 450px;
    height: auto;
    background-color: $grey;
    @extend %section-spacing;

    &--fade {
        padding: 0;
        @extend %content_fade;

        @media #{ $small } {
            height: auto;
        }
    }

    &__inner {
        height: 100%;
        padding: 3rem 2.5rem;
        overflow: auto;
        text-align: justify;

        @media #{ $small } {
            overflow: visible;
            height: auto;
        }
    }

    .single &,
    .page-template-template-contact & {
        position: relative;
    }

    .page-template-template-contact & {
        display: none;
    }

    @media #{ $nav-mobile } {
        order: 2;
        min-width: auto;
    }

    @media #{ $small } {
        &__inner {
            padding: 1.2rem 1rem;
        }
    }

}


/*
    Sidebar
 */

.l-sidebar {

    display: flex;
    flex-direction: column;
    flex: 0 0 #{ $sidebar_width + 2px };
    max-width: #{ $sidebar_width + 2px };
    overflow: auto;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    z-index: 101;

    background-color: $lightgrey;
    @extend %section-spacing;

    @media #{$nav-mobile} {
        flex: 0 0 $offcanvas_width;

        padding: 5.5rem 1rem 1.2rem;
        max-width: 100%;

        /*
        .is-open ~ & .m-logo {
            width: $offcanvas_width;
        }
        */
    }

    @media #{$small} {
        padding: #{1.8rem + 4rem} 1rem 1.2rem 1rem;
        flex: 0 0 auto;
    }

}


/*
    Home
*/

.l-home {

    flex: 1 0 auto;

}