/* ===========================
   Utilities
   =========================== */

/*
	Display
 */

.hidden {
    display: none !important;
}


/*
	Alignment
 */

%wrapper {
	max-width: $wrapper-width;
	width: 100%;
}


/*
    Content fade
 */

%content_fade {

    @include custom( #{$tablet-width}, 'min') {
        position: relative;
        min-height: 100vh;
        max-height: 100vh;

        .l-content__inner {
            max-height: 100vh;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            z-index: 5;
            width: calc(100% - 2rem);
            margin: auto;
            height: 5rem;
            background: #fff;
        }

        &:before {
            top: 0;
            background: linear-gradient($grey,rgba($grey,0));
        }

        &:after {
            bottom: 0;
            background: linear-gradient(rgba($grey,0),$grey);
        }
    }

    @media #{$small} {
        height: 100%;
    }
}


/*
	Classes
 */

.title {
    display: block;
    margin-bottom: 1.2rem;
    color: $red;

    &--sub {
        color: $black;
        margin-bottom: .3rem;
    }
}

.nav {
    margin: 0;
    padding: 0;

    ul {
        margin: 0;
        padding: 0;

    }

    li {
        list-style: none;
    }

    &--services {
        margin-bottom: 1.5rem;
    }
}

.morelink {
    display: block;
    color: $red;
    font-weight: 600;
    margin: .5rem 0 .7rem;

    &:last-child {
        padding-bottom: 1rem;
    }
}

.download {
    position: relative;
    padding-left: 2.5rem;
    margin-top: .5rem;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -.4rem;
        height: 1.8rem;
        width: 1.8rem;
        background: {
            image: url(../images/pfeil_schwarz.svg);
            size: contain;
        }
    }

}

.webcam {

    @extend .download;
    margin-top: 1.5rem;

}