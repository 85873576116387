/* ===========================
   Variables
   =========================== */

// Color
$grey: rgb(218,218,218);
$lightgrey: rgb(236,236,236);
$red: rgb(227,6,19);
$white: rgb(255,255,255);
$black: rgb(0,0,0); // rgb(87,86,86)

//Sizes
$wrapper-width: 1060px;
$wrapper-padding: 20px;
$nav-height: 68px;

$sidebar_width: 350px;
$content_width: 550px;
$offcanvas_width: 320px;

// Media Queries
$phablet-width: 410px;
$tablet-width: 850px;
$laptop-width: 1050px;
$desktop-width: 1280px;