/* =======================
   Modules
   ======================= */

/*
    Logo
 */

.m-logo {

    display: block;
    height: 2rem; // auto
    margin-bottom: 2rem;

    @media #{$nav-mobile} {
        //padding-left: 3.2rem;
        position: fixed;
        top: 0;
        left: 0;
        width: $offcanvas_width;
        height: 4rem;
        display: flex;
        justify-content: flex-start;
        padding-left: 5rem;
        background-color: $white;
        border-bottom: solid 2px $grey;
        z-index: 99;
    }

    @media #{$small} {
        right: 0;
        width: 100%;
    }

    &--home {
        padding: 5rem 3rem 0 0;
        border: none;
        height: auto;

        @media #{$nav-mobile} {
            padding-right: 0;
            padding-top: 7rem;
            max-width: 25rem;
            width: 90%;
            height: auto;
            background-color: transparent;
            margin: 0 auto;
        }
    }

    &--mobile {
        display: none;
        padding-top: 1.5rem;
        padding-left: 4rem;

        @media #{$nav-mobile} {
            //display: block;
        }

        img {
            max-width: 9.5rem !important;
        }
    }

    img {
        max-width: 12rem;
        border: none; // IE
    }

}


/*
    Mainmenu
*/

.m-menu {

    a {
        font-size: 1.2rem;
        color: $white;
        text-transform: uppercase;
        line-height: 1.4;
        font-weight: 700;

        &:hover {
            color: rgba($red, .6);
        }
    }

    .current_page_item a,
    .current_page_parent a,
    .single-team & .page-item-11 a,
    .single-immo & .page-item-15 a,
    .single-property & .page-item-7 a,
    .tax-list & .page-item-7 a {
        color: $red;
    }

    & > ul > li:nth-last-child(2) {
        margin-top: 50px;;
    }

}


/*
    Submenu
*/

%submenu_item {
    color: $black;
    font-weight: 600;
    line-height: 1.5;
    transition: color .3s ease;

    &:hover {
        color: lighten($red, 20);
    }
}

.m-submenu {

    margin-bottom: 1.2rem;

    a {
        @extend %submenu_item;
    }

    h2 {
        @extend %submenu_item;
    }

    .current_page_item a,
    .is-current {
        color: $red;
    }

    .page-item-223 {
        margin-top: 1rem;
    }

}

.m-hamburger {
    display: none;
    position: fixed;
    top: 0;
    left: 0;

    &.is-open {
        width: $offcanvas_width;
    }

    @media #{$nav-mobile} {
        display: block;
    }

    transition: left .5s ease 0s;
    //position: absolute;
    //right: -3px;
    //top: 0;
    z-index: 15;
    width: 4rem;
    height: 4rem;
    padding: 0;
    color: #fff;
    border: none;
    background-color: #fff;
    cursor: pointer;
    z-index: 111;
    outline: none;
    border-right: solid 2px $grey;
    border-bottom: solid 2px $grey;
    transition: width .5s ease;

    &:after {
        content: "";
        position: absolute;
        display: block;
        height: 0;
        top: 50%;
        margin-top: -.5rem;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 0 0 1px $red,0 7px 0 1px $red,0 14px 0 1px $red;
        width: 1rem;
    }

    /*
    //@include custom('736px') {
        width: 5em;
        height: 5em;
        font-size: 40px;
        &__icon, &__icon::before, &__icon::after {
            height: 0.125em;
            width: 2em;
            background-color: black;
        }
        &__icon::before, &__icon::after {
            content: '';
            position: absolute;
            left: 0;
            transition: transform .2s;
        }
        &__icon {
            position: relative;
            margin: auto;
            transition: all .25s;
            &::before {
                transform: translateY(-.75em);
            }
            &::after {
                transform: translateY(.75em);
            }
        }
    //};
    */
}

.m-socials {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-top: 2rem;

    & > li {
        margin-right: 1rem;
    }

    &__icon {
        max-width: 35px;
    }
}


/*
    Overview
 */

.m-overview {

    flex-grow: 1;
    overflow-y: auto;

}


/*
    Image Content
 */

.m-image {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    &__slider {

        height: 50vh;

        @media #{ $nav-mobile } {
            min-height: 250px;
            height: 80vh;
        }

        &.is-fullscreen {
            height: 100%;
        }

    }


    &__scheme {

        height: 50vh;

        @media #{ $nav-mobile } {
            //min-height: 250px;
            //height: 80vh;
            height: auto;
            padding-bottom: 100%;
        }

        background: {
            repeat: no-repeat;
            size: cover;
            position: center;
        }

    }


    &__button {

        //@extend .glide__arrow;
        position: absolute;
        color: $white;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        text-indent: -999em;
        border: none;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        cursor: pointer;

        background: {
            repeat: no-repeat;
            size: 2.2rem; // contain
            position: center;
        }

        &--fullscreen {
            bottom: 2rem;
            background-image: url('../images/plus_weiss.svg');

            .is-fullscreen & {
                display: none;
            }

            @media #{$nav-mobile} {
                display: none;
            }
        }

        &--next {
            top: 2rem;
            left: 45%;
            background-image: url('../images/pfeil_weiss.svg');
        }

        &--prev {
            top: 2rem;
            left: 55%;
            background-image: url('../images/pfeil_weiss_prev.svg');
        }

        &--close {
            bottom: 2rem;
            left: auto;
            right: 2rem;
            background-image: url('../images/plus_weiss.svg');
            transform: rotate(45deg);
        }

    }

}


/*
    Table
 */

.m-table {

    text-align: left;


    &__head,
    &__row {

        display: flex;
        flex-direction: row;
        //justify-content: space-between;
        width: 100%;

        @include custom(550px) {
        //@media #{ $small } {
            //flex-direction: column;
            flex-wrap: wrap;
        }

    }

    &__wrapper {
        display: flex;

        margin: .8rem 0;

        @include custom(1400px) {
            flex-direction: column;
        }

        &--main {
            width: 46%;

            @include custom(550px) {
            //@media #{ $small } {
                width: 100%;

                .m-table__item {
                    width: 100%;
                }
            }
        }

        &--meta {
            width: 40%;
            flex-grow: 1;
        }

        &--link {
            width: 14%;
        }

    }

    &__item {
        padding: 0 .8rem 0 0;

        // Modifier
        &--object {
            //width: 26%
            width: 56%;
        }

        &--constructor {
            //width: 20%;
            width: 44%;
        }

        &--place {
            //width: 10%;
            width: 25%;
        }

        &--year {
            //width: 10%;
            width: 25%;
        }

        &--service {
            //width: 20%;
            width: 50%;
        }

        &--detail {
            //width: 7%;
            width: 50%;
        }

        &--pdf {
            //width: 7%;
            width: 50%;
        }

    }

    &__head {
        margin-bottom: .5rem;

        @include custom(550px) {
            display: none;
        }

        + .m-table__row {
            border-top: solid 1px lighten($black, 25);
            //padding-top: .8rem;
            margin-top: 0;
        }

    }


    &__row {

        border-bottom: solid 1px lighten($black, 25);
        margin: 0 0;

        a {
            color: $red;

            &:hover {
                color: darken($red, 15);
            }
        }

        @media #{$small} {
            width: calc(100% + 2rem);
            padding: 1rem;
            margin-left: -1rem;
            border: none;

            &:nth-child(odd) {
                background-color: $lightgrey;
            }

        }

    }

}

.m-team {
    &:last-child {
        border-top: solid 1px black;
        margin-top: 10px;
        padding-top: 5px;
    }
}


/*
    Customization GlideJS
 */

.glide__slide {

    background: {
        repeat: no-repeat;
        size: cover;
        position: center;
    }

}


/*
    Google Maps
 */

.m-map {

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 250px;
    width: 100%;

}